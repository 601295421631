import React from 'react'

const People2Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.173"
      height="18.88"
      viewBox="0 0 25.173 18.88"
    >
      <path
        id="people-svgrepo-com"
        d="M23.6,20.88a1.541,1.541,0,0,0,1.573-1.573c0-1.573-1.573-6.293-7.867-6.293S9.44,17.733,9.44,19.307a1.541,1.541,0,0,0,1.573,1.573ZM11.048,19.307l-.035-.006a4.828,4.828,0,0,1,1.2-2.706,6.3,6.3,0,0,1,5.1-2.008,6.306,6.306,0,0,1,5.1,2.008A4.844,4.844,0,0,1,23.6,19.3l-.013,0-.022,0Zm6.259-9.44A3.147,3.147,0,1,0,14.16,6.72,3.147,3.147,0,0,0,17.307,9.867Zm4.72-3.147A4.72,4.72,0,1,1,17.307,2,4.72,4.72,0,0,1,22.027,6.72ZM10.913,13.454a9.251,9.251,0,0,0-1.935-.389q-.554-.053-1.111-.052C1.573,13.013,0,17.733,0,19.307A1.39,1.39,0,0,0,1.573,20.88H8.206a3.521,3.521,0,0,1-.34-1.573,7.22,7.22,0,0,1,1.715-4.569,8.325,8.325,0,0,1,1.331-1.284ZM7.741,14.587a8.642,8.642,0,0,0-1.447,4.72H1.573a4.828,4.828,0,0,1,1.2-2.712,6.252,6.252,0,0,1,4.972-2.006ZM2.36,7.507a4.72,4.72,0,1,1,4.72,4.72A4.72,4.72,0,0,1,2.36,7.507ZM7.08,4.36a3.147,3.147,0,1,0,3.147,3.147A3.147,3.147,0,0,0,7.08,4.36Z"
        transform="translate(0 -2)"
      />
    </svg>
  )
}

export default People2Icon
