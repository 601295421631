import React from 'react'

const FourOhFour = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="225"
      height="225"
      className={className}
      fill="#212529"
      x="0"
      y="0"
      enableBackground="new 0 0 53.938 53.938"
      version="1.1"
      viewBox="0 0 53.938 53.938"
      xmlSpace="preserve"
    >
      <path d="M26.969 53.938c-14.871 0-26.968-12.1-26.968-26.97S12.098 0 26.969 0s26.968 12.099 26.968 26.969c0 14.87-12.097 26.969-26.968 26.969zm0-49.993c-12.695 0-23.022 10.329-23.022 23.023 0 12.693 10.327 23.021 23.022 23.021 12.694 0 23.021-10.328 23.021-23.021-.001-12.694-10.327-23.023-23.021-23.023z" />
      <path d="M15.217 40.079c-.492 0-.983-.181-1.367-.55a1.97 1.97 0 01-.058-2.787c3.611-3.766 8.471-5.84 13.687-5.84 4.629 0 9.084 1.687 12.549 4.748a1.973 1.973 0 01-2.615 2.957 14.983 14.983 0 00-9.934-3.758 14.903 14.903 0 00-10.838 4.621 1.963 1.963 0 01-1.424.609z"></path>
      <circle cx="20.872" cy="24.363" r="2.604" />
      <circle cx="33.067" cy="24.363" r="2.604" />
    </svg>
  )
}

export default FourOhFour
